export const claimStatus = {
  processed: 270,
  received: 267,
  approved: 269,
  declined: 435,
  pending: 437,
  inProgress: 268,
  unused: 434,
  settled: 436,
  partialSettled: 745,
  returned: 438,
  forward: 738,
  routed: 738,
};

export const claimServiceActions = {
  priorApproval: 433,
  rejected: 432,
  approved: 288,
};

// export const benefitIds = {
//   inPatient: 1,
//   outPatient: 256,
//   pharmacy: 257,
//   emergencyRoom: 258,
// }
export const benefitIds = {
  inPatient: 1,
  outPatient: 218,
  ambulatory: 218,
  pharmacy: 277,
  emergencyRoom: 11,
};

export const claimsTypes = {
  reimbursement: 234,
  directBilling: 233,
};

export const claimRuleTriggerPredefined = [
  // physician speciality
  {
    triggerId: 442,
    predefinedId: 8,
  },
  // Beneficiaary Depenedency
  {
    triggerId: 484,
    predefinedId: 176,
  },
  // Beneficiaary Geneder
  {
    triggerId: 446,
    predefinedId: 173,
  },
  // Family of cause
  {
    triggerId: 443,
    predefinedId: 235,
  },
];

export const allowedPrintPermissionStatuses = [
  claimStatus.declined,
  claimStatus.approved,
];
export const hidePrintPermissionStatuses = [
  claimStatus.inProgress,
  claimStatus.pending,
  claimStatus.unused,
];

export const hideProcessingPermissionStatuses = [
  ...hidePrintPermissionStatuses,
  claimStatus.declined,
  claimStatus.approved,
  claimStatus.returned,
];

export const declinedButtonStatuses = [
  claimStatus.declined,
  claimStatus.returned,
  claimStatus.received,
  claimStatus.processed,
];
export const saveButtonStatuses = [
  ...declinedButtonStatuses,
  claimStatus.unused,
  claimStatus.settled,
];
export const approvedButtonStatuses = [
  claimStatus.inProgress,
  claimStatus.pending,
  claimStatus.declined,
];
export const pendingButtonStatuses = [
  claimStatus.processed,
  claimStatus.unused,
  claimStatus.settled,
  claimStatus.received,
  claimStatus.returned,
];

export const claimErrorMessages = {
  // exceedLimits:
  //   "The Estimation Amount has surpassed the FOB Remaining Limit. Please Check the Beneficiary Note Box",
  exceedLimits:
    "The Estimation Amount cannot be greater than the Remaining Limit. Please Check the Beneficiary's Notes if any",
  amountSumexceedLimits:
    "The Sum of benefits amounts has surpassed the Approved Estimation Amount.",
  // selectRejectReason: "Please select rejected reason to proceed.",
  selectRejectReason:
    "A claim that contains only rejected items cannot be saved",
  changeFromPrior: "Please change status from Proir Approval",
  emptyServices: "Please add Service to proccess",
  notRejectedNotApproved:
    "Please check the item status before proceeding with claim processing.",
  cantAppovedItem:
    "This item cannot be approved since the total approved amount is 0.",
  attachMedicalFiles: "Please attach the medical report",
};

export const countSlugs = {
  all: "claims-grouped-by-type-and-status",
  approved: "claims-grouped-by-type-and-approved-status-without-discharge-date",
  unreadChats: "claims-unread-chat-count",
};
