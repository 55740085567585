import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import moment from "moment";
import { APIDateFormat } from "constants/TPA";
import { countSlugs } from "common/data";
import { getKPI } from "helpers/General/kpis_helper";
import { Skeleton } from "antd";
import { claimPaths } from "common/data/routePaths";
import { getLoggedInUser, getMainProvider } from "utils";

const UnreadChatsNotifactionsIcons = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);

  const getUnreadChatsCount = async () => {
    const parms = {
      start_date: moment()
        .add(-1, "year")
        .startOf("year")
        .format(APIDateFormat),
      end_date: moment().add(1, "year").format(APIDateFormat),
      condition_keys: [
        "access_type_ids",
        "user_access_type_ids",
        "healthcare_provider_id",
        "branch_id",
      ],
      condition_values: [
        2,
        1,
        getMainProvider().id,
        getLoggedInUser()?.branch?.id,
      ],
    };

    setIsLoading(true);
    try {
      // all claims
      const res = await getKPI(countSlugs.unreadChats, parms, true);
      setCount(res?.item?.data?.count || 0);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("error", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  // reload function
  const refetch = () => {
    getUnreadChatsCount();
  };

  // useEffect(() => {
  //   getUnreadChatsCount();
  // }, []);

  const ref = useRef(null);

  // refresh every 2 minutes
  // useEffect(() => {
  //   ref.current = setInterval(refetch, 2 * 60 * 1000);
  //   return () => {
  //     if (ref.current) {
  //       clearInterval(ref.current);
  //     }
  //   };
  // }, []);

  if (isLoading)
    return (
      <li className="dropdown d-inline-block" style={{ width: "40px" }}>
        <Skeleton
          active
          avatar={false}
          title={false}
          style={{
            margin: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "5px",
          }}
          paragraph={{
            rows: 1,
            style: { margin: "0px" },
          }}
        />
      </li>
    );

  return (
    <React.Fragment>
      <li className="dropdown d-inline-block">
        <Link
          to={`${claimPaths.claimForm}#unread_chats`}
          className="btn noti-icon outline-none border-0 position-relative"
        >
          <i className="mdi mdi-chat-processing-outline" />
          {/* Number of Notifications */}
          {count > 0 ? (
            <span
              className="badge bg-danger rounded-pill font-size-10"
              style={{
                position: "absolute",
                top: "0px",
              }}
            >
              {count}
            </span>
          ) : null}
        </Link>
      </li>
    </React.Fragment>
  );
};

export default withTranslation()(UnreadChatsNotifactionsIcons);

UnreadChatsNotifactionsIcons.propTypes = {
  t: PropTypes.any,
};
